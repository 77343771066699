import React, { useEffect, useState, useRef } from 'react';
import "./Cell.css";
import { Tooltip } from "@mui/joy";

const Cell = (props) => {
    const {
        obj: initialObj,
        inputs,
        lessonAdding,
        setNewLessons,
        newLessons,
        setPrevLessons,
        prevLessons,
        lesson
    } = props;

    const [obj, setObj] = useState(initialObj);
    const [subjectShow, setSubjectShow] = useState(false);
    const tooltipRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setSubjectShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tooltipRef]);

    const shortSubject = subjectShow && obj.subjectFirst
        ? obj.subjectFirst
        : <>
            {
                obj.subjectFirst?.includes("МДК")
                    ? obj.subjectFirst?.substr(0, 10)
                    : obj.subjectFirst?.includes("Иностранный язык")
                        ? "Иностранный язык"
                        : obj.subjectFirst?.includes("Физическая культура")
                            ? <>Физическая&nbsp;культура</>
                            : obj.subjectFirst?.includes("Основы безопасности жизнедеятельности")
                                ? "ОБЖ"
                                : obj.subjectFirst?.length > 12
                                    ? obj.subjectFirst?.substr(0, 12) + "..."
                                    : obj.subjectFirst
            }
        </>;

    const handleClick = () => {
        if (lessonAdding) {
            const updatedPrevLessons = [obj, ...prevLessons];
            const updatedNewLessons = [{
                groupNumber: obj.groupNumber,
                time: obj.time,
                weekDay: obj.weekDay,
                subjectFirst: lesson.subjectFirst,
                teacherFirst: lesson.teacherFirst,
                auditoryFirst: lesson.auditoryFirst,
                subjectSecond: lesson.subjectSecond,
                teacherSecond: lesson.teacherSecond,
                auditorySecond: lesson.auditorySecond,
            }, ...newLessons];

            console.log('Updating prevLessons:', updatedPrevLessons);
            console.log('Updating newLessons:', updatedNewLessons);

            setPrevLessons(updatedPrevLessons);
            setNewLessons(updatedNewLessons);

            setObj({
                groupNumber: obj.groupNumber,
                time: obj.time,
                weekDay: obj.weekDay,
                subjectFirst: lesson.subjectFirst,
                teacherFirst: lesson.teacherFirst,
                auditoryFirst: lesson.auditoryFirst,
                subjectSecond: lesson.subjectSecond,
                teacherSecond: lesson.teacherSecond,
                auditorySecond: lesson.auditorySecond,
            });
        }
    };

    return (
        <tr style={{ minWidth: "10em" }}>
            <div
                className="cell"
                onClick={handleClick}
                style={
                    (obj.teacherFirst?.toLowerCase().includes(inputs.toLowerCase())
                    && inputs.length >= 3 && isNaN(inputs)) ||
                    (obj.teacherSecond?.toLowerCase().includes(inputs.toLowerCase())
                    && inputs.length >= 3 && isNaN(inputs)) ||
                    (obj.subjectFirst?.toLowerCase().includes(inputs.toLowerCase())
                    && inputs.length >= 3 && isNaN(inputs))
                        ? {}
                        : inputs.length < 3
                            ? {}
                            : { filter: "opacity(0.5)" }
                }
            >
                <div className="main-info">
                    <abbr title="Номер урока">
                        <h4>
                            {
                                obj.time.includes("9:00") ? 1
                                    : obj.time.includes("10:00") ? 2
                                        : obj.time.includes("11:00") ? 3
                                            : obj.time.includes("12:00") ? 4
                                                : obj.time.includes("13:05") ? 5
                                                    : obj.time.includes("14:10") ? 6
                                                        : obj.time.includes("15:05") ? 7
                                                            : obj.time.includes("15:55") ? 8
                                                                : ""
                            }
                        </h4>
                    </abbr>

                    <abbr title="Урок">
                        <Tooltip title={obj.subjectFirst} arrow open={obj?.subjectFirst?.length > 12 && subjectShow} placement="bottom">
                            <h2
                                ref={tooltipRef}
                                className="subject"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSubjectShow(!subjectShow);
                                }}
                            >
                                {shortSubject}
                            </h2>
                        </Tooltip>
                    </abbr>
                </div>

                {obj.subjectSecond ?
                    <div className="extra-info">
                        <p className="extra-info__text">
                            <span className="teacher">
                                {obj.teacherFirst}
                            </span>
                            <span className="auditory">
                                {obj.auditoryFirst}
                            </span>
                        </p>
                        <p className="extra-info__text">
                            <span className="teacher">
                                {obj.teacherSecond}
                            </span>
                            <span className="auditory">
                                {obj.auditorySecond}
                            </span>
                        </p>
                    </div>
                    :
                    <div className="extra-info">
                        <p className="teacher">
                            {obj.teacherFirst}
                        </p>
                        <p className="auditory">
                            {obj.auditoryFirst}
                        </p>
                    </div>
                }
            </div>
        </tr>
    );
};

export default Cell;
