import React, { useEffect, useState } from 'react';
import "./AutoComplete.css";

const AutoComplete = ({ setInput, array, value }) => {
    const [isSelectVisible, setIsSelectVisible] = useState(false);

    const uniqArray = [...new Set(array)];
    const selectSize = Math.min(uniqArray.length, 3);

    useEffect(() => {
        if (value !== "") setIsSelectVisible(true);
        else setIsSelectVisible(false);
    }, [value]);

    const handleClick = (item) => {
        setIsSelectVisible(false);
        setInput(item);
    };

    if (isSelectVisible && uniqArray.length > 1) {
        return (
            <select className="auto-complete" size={selectSize}>
                {uniqArray.map((item, index) => (
                    <option
                        key={index}
                        className="auto-complete__item"
                        onClick={() => handleClick(item)}
                    >
                        {item}
                    </option>
                ))}
            </select>
        );
    }

    return null;
};

export default AutoComplete;
