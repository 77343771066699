import React, {useState, useEffect} from 'react';
import styles from "./Profile.module.css";
import Loader from '../../Components/Loader/Loader';
import {Link, Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchSchedule, selectSchedule} from "../../Redux/Slices/schedule";
import {selectIsAuth} from "../../Redux/Slices/auth";
import axios from "../../axios";
import {Avatar, Button, Card, Chip, Container, Typography} from "@mui/joy";

const Profile = () => {

    const isAuth = useSelector(selectIsAuth);
    const [userData, setUserData] = useState({})
    const [scheduleData, setScheduleData] = useState();

    useEffect(() => {
        setUserData(JSON.parse(window.localStorage.getItem('userData')));
    }, []);


    const dispatch = useDispatch();
    const schedule = useSelector(selectSchedule);

    const isLoading = schedule.status === 'loading'

    const getSchedule = async () => {
        const data = await dispatch(fetchSchedule());
        setScheduleData(data)
        console.log(data)
    }

    useEffect(() => {
        getSchedule();
    }, []);

    if (scheduleData?.payload.includes(300)) {
        localStorage.removeItem("userData");
        document.cookie = "PHPSESSID; expires=-1;";
        return <Navigate to="/strange-error"/>
    }

    const logOut = () => {
        localStorage.removeItem('userData');
        axios.post('/logout.php').then(response => console.log(response));
    }

    if (!isAuth) return <Navigate to="/login"/>

    const getRole = (roleIndex, group) => {
        const roles = [
            "Админ",
            `Студент ${group} группы`,
            "Преподаватель",
            "Директор"
        ]

        return roles[roleIndex - 1]
    }

    return (
        <>
            <Loader loading={isLoading}/>
            <div className={styles.ProfilePage}>
                <Container sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px"
                }}>
                    <Card>
                        <Card
                            variant='soft'
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <div style={{
                                display: "flex",
                                gap: 12
                            }}>
                                <Avatar src={`http://185.22.232.236${userData?.iconRef}`} size="lg"/>
                                <h2>
                                    {userData.name} {userData.lastname}
                                </h2>
                            </div>
                            <Chip size="lg">
                                {getRole(userData.access, userData.group)}
                            </Chip>
                            {userData.nameprofession
                                && <Typography 
                                level='h5'
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                Направление: {userData.nameprofession}
                                {' '}({userData.codeProfession})
                            </Typography>
                            }
                            {
                                userData.listSpeciality &&
                                <Typography 
                                level='h5'
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                {userData.listSpeciality}
                            </Typography>
                            }
                            
                        </Card>
                        <div style={{
                            display: "flex",
                            gap: 20,
                        }}>
                            {userData.access === '1' &&
                                <Link to="/admin" style={{color: '#1f1f1f'}}>
                                    <Button>
                                        Войти в админ-панель
                                    </Button>
                                </Link>
                            }
                                <Link to="/schedule">
                                    <Button>
                                        Посмотреть полное расписание
                                    </Button>
                                </Link>
                            <Link to="/login">
                                <Button
                                    color="danger"
                                    variant="soft"
                                    onClick={() => logOut()}
                                >
                                    Выйти
                                </Button>
                            </Link>
                        </div>

                    </Card>

                </Container>
            </div>
        </>

    );
};

export default Profile;