import React from 'react';
import Cell from "../Cell/Cell";
import "./GroupsList.css";
import {Card, Table} from "@mui/joy";

const GroupsList = ({ fromCsv, groups, weekdays, teacherSearching, arraySchedule, inputs, isAdmin, lesson, lessonAdding, updatedLessons, setUpdatedLessons, prevLessons, newLessons, setPrevLessons, setNewLessons }) => {
    return (
        <div className="groups-list">
            {groups?.map(gObj =>
            gObj &&
                    <>
                        <h1 style={{
                            textAlign: "center"
                        }}>
                            ГРУППА <span id="gObj">{gObj}</span>
                        </h1>
                        <Card
                            sx={{
                                maxWidth: "90vw",
                                overflowX: "scroll",
                                zIndex: 1
                            }}
                        >
                            <div style={{
                                minWidth: 1000,
                                maxWidth: "90vw",
                                overflowX: "auto",
                                tableLayout: "auto"
                            }}>
                                <Table>
                                    <thead>
                                    <tr>
                                        {weekdays.map(wObj =>
                                            <th key={wObj}>
                                                <h2 className="day-cell">{wObj}</h2>
                                            </th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {weekdays.map((wObj, index) => {
                                            const day = (arraySchedule.filter(item => item.weekDay === wObj
                                                && item.groupNumber === gObj))

                                            return (
                                                <th key={index}>
                                                    {day.map((obj, index) =>
                                                        <Cell
                                                            fromCsv={fromCsv}
                                                            prevLessons={prevLessons}
                                                            newLessons={newLessons}
                                                            setPrevLessons={setPrevLessons}
                                                            setNewLessons={setNewLessons}
                                                            lessonAdding={lessonAdding}
                                                            setUpdatedLessons={setUpdatedLessons}
                                                            updatedLessons={updatedLessons}
                                                            obj={obj}
                                                            inputs={inputs}
                                                            teacherSearching={teacherSearching}
                                                            index={index}
                                                            isAdmin={isAdmin}
                                                            lesson={lesson}
                                                            key={index}
                                                        />
                                                    )}
                                                </th>
                                            )
                                        }
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </>
            )}
        </div>
    );
};

export default GroupsList;